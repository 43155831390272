import { defineStore } from 'pinia'

export const useCarbarStore = defineStore('carbar', {
	namespaced: true,
	state: () => ({
		header: {
			signUpUserType: 'personal',
			showMobileMenu: false,
		},
		message: {
			title: null,
			error: null,
			success: null,
		},
		vehicleCount: {
			subscription: 0,
		},
		disabledDates: {},

		// Novated Lease Promo
		novatedLeasePromo: {
			isPreselected: false,
		},
	}),
	getters: {},
	actions: {
		clearMessage() {
			this.message.title = null
			this.message.error = null
			this.message.success = null
		},
	},
})
